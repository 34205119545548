import Cookies from "js-cookie";

/**
 * Load Mouseflow for US only, and ~25% recording rate, with active cookie for persistence.
 */
export default function mouseflowStart(geoCountry) {
  const mouseflowCookieName = "rl_mouseflow_active";

  let mouseflowActive = Cookies.get(mouseflowCookieName);

  if (mouseflowActive === "active" || (geoCountry === "US" && Math.random() <= 0.25)) {
    /** Mouseflow script */
    window._mfq = window._mfq || [];
    (function () {
      var mf = document.createElement("script");
      mf.type = "text/javascript";
      mf.defer = true;
      mf.src = "//cdn.mouseflow.com/projects/561d458b-a7d7-4f24-bbae-c7bbad69b118.js";
      document.getElementsByTagName("head")[0].appendChild(mf);
    })();

    Cookies.set(mouseflowCookieName, "active", { expires: 1, sameSite: "Strict" });
  }
}
