import { v4 as uuidV4 } from "uuid";

/**
 * UUID for FB Pixel tracking event de-duplication.
 * Used in JS tag with fbq('track'), and sent to the 'check-in' route for server-side Conversions API tracking.
 * @see https://developers.facebook.com/docs/marketing-api/conversions-api/deduplicate-pixel-and-server-events
 * @see https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/server-event#event-id
 */
export default function setupEventId() {
  window.rlEventId = uuidV4();
}
