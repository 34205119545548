/** @see https://laravel.com/docs/11.x/vite#blade-processing-static-assets */
import.meta.glob(["../images/**", "../video/**"]);

/** Packages. */
import "instant.page";

/** Modules. */
import checkIn from "./app/modules/checkIn.js";
import cookieConsent from "./app/modules/cookieConsent.js";
import setupAlpineAndLivewire from "./app/modules/setupAlpineAndLivewire.js";
import setupAxios from "./app/modules/setupAxios.js";
import setupCash from "./app/modules/setupCash.js";
import setupEventId from "./app/modules/setupEventId.js";
import updateViewportHeightProperty from "./app/modules/updateViewportHeightProperty.js";
import { loadAmmap } from "./app/modules/helpers.js";

/** Ammap loader helper. TODO: refactor usages to async Alpine components. */
window.loadAmmap = loadAmmap;

/** Initialise packages & modules (Alpine.js & Livewire must start after other packages). */
setupAxios();

setupCash();

setupEventId();

setupAlpineAndLivewire();

checkIn(window.rlEventId);

updateViewportHeightProperty();

cookieConsent();

/** Import legacy filter functions partial. */
import "./app/partials/filter.js";
