export default function enquireModalAlpineStore() {
  Alpine.store("enquireModal", {
    isOpen: false,
    isLoaded: false,

    init() {
      /** Auto-show modal when URL has '#enquire' hash, e.g. https://www.remotelands.test/#enquire */
      if (window.location.hash === "#enquire") {
        this.load();
      }
    },

    toggle() {
      this.isOpen = !this.isOpen;
    },

    /** @param {EnquireTab} tab */
    load(tab = "online") {
      if (this.isLoaded) {
        this.isOpen = true;

        return;
      }

      this.isOpen = true;

      let tripItinerarySlug = null;

      if (route().current("page.aman-jet-expeditions.show") || route().current("page.group-tours.show")) {
        tripItinerarySlug = route().params.amanJetExpedition ?? route().params.groupTour;
      }

      window.axios
        .get(
          route("ajax.enquire.open", {
            tripItinerary: tripItinerarySlug,
            tab: tab,
          }),
        )
        .then((response) => {
          Alpine.store("enquireModal").isLoaded = true;

          document.getElementById("enquire_modal").innerHTML = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  });
}
