import debounce from "lodash/debounce";

/**
 * Updates our '100% view height' custom CSS variable value.
 * @see https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
 * @return void
 */
export default function updateViewportHeightProperty() {
  /** Set the value in the --vh100 custom property on the window resize event */
  window.addEventListener(
    "resize",
    debounce(() => document.documentElement.style.setProperty("--vh100", `${window.innerHeight}px`), 100),
  );

  /** Fire the resize event to set an initial value */
  window.dispatchEvent(new Event("resize"));
}
