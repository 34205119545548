/**
 * https://stackoverflow.com/a/53744331
 *
 * @param {string} scriptUrl
 * @returns {Promise}
 */
export function loadScript(scriptUrl) {
  const script = document.createElement("script");

  script.src = scriptUrl;
  script.async = true;
  script.defer = true;
  script.type = "module";

  document.body.appendChild(script);

  return new Promise((resolve, reject) => {
    script.onload = function () {
      resolve();
    };
    script.onerror = function () {
      reject();
    };
  });
}

/**
 * Loads the AmCharts library and map data script sequentially.
 * @param {string} libraryScript
 * @param {string} mapScript
 */
export function loadAmmap(libraryScript, mapScript) {
  return loadScript(libraryScript)
    .then(function () {
      return loadScript(mapScript).catch((error) => console.error(`AmCharts map load error: ${error}`));
    })
    .catch((error) => console.error(`AmCharts library load error: ${error}`));
}

/**
 * @param {number} size
 */
export function screenUp(size) {
  return matchMedia(`screen and (min-width: ${size}px)`).matches;
}
