import $ from "cash-dom";

/** Axios init & global */
export default function setupCash() {
  /**
   * $() global function.
   * @deprecated For new code, use vanilla JS / AlpineJS / Livewire.
   */
  window.$ = $;

  /**
   * @param {function} onMouseEnterCallback
   * @param {function} onMouseLeaveCallback
   * @returns {$}
   * @see https://github.com/fabiospampinato/cash/blob/master/docs/extending_cash.md#collection-method
   */
  $.fn.hover = function (onMouseEnterCallback, onMouseLeaveCallback) {
    this.on("mouseenter", onMouseEnterCallback);
    this.on("mouseleave", onMouseLeaveCallback);

    return this;
  };

  /**
   * @return {number|null}
   * @see https://api.jquery.com/scrollTop/#scrollTop
   */
  $.fn.scrollTop = function () {
    const element = this.get(0);

    if (!element) {
      return null;
    }

    return element.scrollTop ?? element.scrollY;
  };

  /**
   * Patches jQuery's AJAX 'post' function.
   * @see https://github.com/fabiospampinato/cash/blob/master/docs/extending_cash.md#static-method
   */
  $.post = function (url, data, callback) {
    window.axios.post(url, data).then((response) => {
      callback(response.data);
    });
  };

  /**
   * Event shorthands.
   * @see https://github.com/fabiospampinato/cash/blob/master/src/extra/shorthands.ts
   */
  $.each(
    [
      "blur",
      "focus",
      "focusin",
      "focusout",
      "resize",
      "scroll",
      "click",
      "dblclick",
      "mousedown",
      "mouseup",
      "mousemove",
      "mouseover",
      "mouseout",
      "mouseenter",
      "mouseleave",
      "change",
      "select",
      "submit",
      "keydown",
      "keypress",
      "keyup",
      "contextmenu",
    ],
    (index, eventName) => {
      $.fn[eventName] = function (callback) {
        if (callback) {
          return this.on(eventName, callback);
        }

        return this.trigger(eventName);
      };
    },
  );
}
